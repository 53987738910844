<!--
 * @Description: 签到/换班  signIn
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-08 14:27:19
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="签到"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <!-- 地图 -->
    <div class="map-container"
         id="map">
    </div>
    <!-- 名字 -->
    <van-row class="Info">
      <van-col :span=12
               class="info-left">
        <van-image class="left-image"
                   :src="require('../../assets/signIn/touxiang.png')" />
        <span style="margin-left:2%">{{userName}}</span>
      </van-col>
      <van-col :span=12
               class="info-right">
        <van-image class="right-image"
                   :src="require('../../assets/signIn/signIn.png')" />
        <span style="margin-left:2%"
              @click="handleClickRemark">签到记录</span>
      </van-col>
    </van-row>
    <!-- 签到 -->
    <van-row class="signBut">
      <van-row class="signButton"
               @click="handleClickChange">
        <van-row v-if="pleaseSign === 0"
                 class="signText">{{signIn}}</van-row>
        <van-row v-if="pleaseSign === 1"
                 class="signText">{{signIn}}</van-row>
        <van-row class="signTime">{{dataTimeLineOne}}</van-row>
        <van-row class="signTime">{{dataTimeLineTwo}}</van-row>
      </van-row>
    </van-row>
    <!-- 签到时间/签退时间 -->
    <van-row class="signInTimeClass">
      <span class="signInTimeBut"></span>
      <span class="signInTimeText">签到时间:</span>
      <span class="signInTimeContent">{{signInTime}}</span>
    </van-row>
    <van-row class="signOutTimeClass">
      <span class="signOutTimeBut"></span>
      <span class="signOutTimeText">签退时间:</span>
      <span class="signOutTimeContent">{{signOutTime}}</span>
    </van-row>
    <!-- 迟到原因 -->
    <van-dialog v-model="lateShow"
                @confirm="handleClickLateConfirm"
                title="备注">
      <van-field v-model="lateText"
                 placeholder="请输入迟到原因" />
    </van-dialog>
    <!-- 早退原因 -->
    <van-dialog v-model="beforeShow"
                @confirm="handleClickBeforeConfirm"
                title="备注">
      <van-field v-model="beforeText"
                 placeholder="请输入早退原因" />
    </van-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// 获取当前定位
// import currentPosition from '@/utils/currentPosition'
// 初始化地图组件
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      pleaseSign: 0, // 显示签到/签退 0为签到 1位签退
      userName: '', // 用户名
      signIn: '签到',
      dateTimeValue: '', // 定时任务获取当前时间
      dataTimeLineOne: '', // 年月日
      dataTimeLineTwo: '', // 时分秒
      signInTime: '未签到', // 签到时间
      signOutTime: '未签退', // 签退时间
      dueInTime: '', // 需要签到时间
      dueOutTime: '', // 需要签退时间
      scheduleSequence: '', // 排班编号
      lateShow: false, // 迟到原因弹窗
      lateText: '', // 迟到原因
      fnLateTime: '', // 是否迟到时间对比
      beforeShow: false, // 早退原因
      beforeText: '', // 早退原因
      fnBeforeTime: '', // 是否早退时间对比
      map: '', // map对象
      parkList: {},
      markerList: [],
      desLng: 106.612501, // 停车场经度
      desLat: 29.717599, // 停车场纬度
      distance: 0, // 距离目标点的距离（米）
      mylongitude: 107.631187, // 当前经度
      mylatitude: 29.718143, // 当前纬度
      myAddress: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.queryLatLng()
    window.android.startLocation()
    window.signInLocation = this.signInLocation

    this.userName = localStorage.getItem('userName')

    this.todaySchedule()

    setInterval(() => {
      this.getDateTime() // 定时任务获取当前时间
    }, 1000)

    // setInterval(() => {
    //   this.querySigreportLocationnIn() // 实时上报定时任务
    // }, 180000)
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.signInLocation()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () {
  }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    signInLocation (location) {
      this.mylatitude = JSON.parse(location).latitude
      this.mylongitude = JSON.parse(location).longitude
      this.myaddress = JSON.parse(location).address
      this.initMap()
    },
    // // 巡检员上报位置
    // querySigreportLocationnIn () {
    //   console.log(this.mylongitude, this.mylatitude)
    //   if (this.mylongitude !== 107.631187 && this.mylatitude !== 29.718143) {
    //     const info = {
    //       longitude: this.mylongitude, // 经度
    //       latitude: this.mylatitude // 纬度
    //     }
    //     console.log(info)
    //     // this.$signIn.reportLocation(info).then(() => {
    //     // })
    //   }
    // },
    // 获取当前停车场的经纬度
    queryLatLng () {
      const info = {
        parkId: this.$cookie.get('managePark').manageParkId
      }
      this.$signIn.queryParkAll(info).then(res => {
        this.parkList = res.resultEntity[0]
        this.desLat = Number(res.resultEntity[0].latitude)
        this.desLng = Number(res.resultEntity[0].longitude)
      })
    },
    // 获取当前位置
    // queryCurrentPlace () {
    //   currentPosition(complete => {
    //     this.mylatitude = complete.position.lat
    //     this.mylongitude = complete.position.lng
    //     this.myaddress = complete.formattedAddress
    //     // 渲染地图
    //     this.initMap()
    //   })
    // },
    // 初始化地图
    initMap () {
      this.markerList = []
      this.map = new window.AMap.Map('map', {
        mapStyle: 'amap://styles/macaron', // 设置地图的显示样式
        resizeEnable: true,
        zoom: 14, // 缩放级别
        center: [Number(this.mylongitude), Number(this.mylatitude)] // 中心点坐标
      })
      // 给当前位置加标点
      const marker = new window.AMap.Marker({
        icon: 'https://vdata.amap.com/icons/b18/1/2.png',
        position: new window.AMap.LngLat(Number(this.mylongitude), Number(this.mylatitude))
      })
      this.map.add(marker)// 自身定位标点
      // 给目标位置加标点 给标点加内容
      const marker1 = new window.AMap.Marker({
        position: new window.AMap.LngLat(Number(this.desLng), Number(this.desLat)),
        parkName: this.parkList.parkName,
        parkSpaceNum: this.parkList.parkSpaceNum,
        longitude: this.parkList.longitude,
        latitude: this.parkList.latitude
      })
      // 标点点击事件
      marker1.on('click', this.markerScenicClick)
      this.markerList.push(marker1)
      this.map.add(marker1)
      // 获取两地之间的距离
      const p1 = marker.getPosition()
      const p2 = marker1.getPosition()
      const distance = Math.round(p1.distance(p2))
      this.distance = distance
      const circle = new window.AMap.Circle({
        center: new window.AMap.LngLat(Number(this.desLng), Number(this.desLat)),
        radius: 1000,
        fillColor: '#5ABCFF',
        fillOpacity: 0.2,
        strokeColor: '#5ABCFF',
        strokeWeight: 2
      })
      this.map.add(circle)
    },
    // 计算两点距离
    markerScenicClick (ev) {
      // 信息窗体的内容
      const content = [
        '停车场名称:' + ev.target.Ce.parkName + ' <br>' + '剩余车位:' + ev.target.Ce.parkSpaceNum
      ]
      // 创建 infoWindow 实例
      const infoWindow = new window.AMap.InfoWindow({
        content: content.join('<br>')
      })
      // 打开信息窗体
      infoWindow.open(this.map, [Number(ev.target.Ce.longitude), Number(ev.target.Ce.latitude)])
    },
    // 获取当日排班
    todaySchedule () {
      this.$signIn.todaySchedule().then(res => {
        localStorage.setItem('scheduleSequence', res.resultEntity.scheduleSequence)
        localStorage.setItem('dueInTime', res.resultEntity.dueInTime)
        localStorage.setItem('dueOutTime', res.resultEntity.dueOutTime)
        this.scheduleSequence = localStorage.getItem('scheduleSequence')
        this.dueInTime = localStorage.getItem('dueInTime')
        this.dueOutTime = localStorage.getItem('dueOutTime')
        // 如果未签到
        if (res.resultEntity.signInTime === undefined) {
          this.signIn = '签到'
          this.signInTime = '未签到'
        } else {
          if (res.resultEntity.signOutTime === undefined) {
            // 已签到未签退
            this.signInTime = res.resultEntity.signInTime
            this.signIn = '签退'
          } else {
            // 已签退
            this.signInTime = res.resultEntity.signInTime
            this.signOutTime = res.resultEntity.signOutTime
            this.signIn = '本日完成'
          }
        }
      })
    },
    // 点击签到按钮
    handleClickChange () {
      // 判断距离是否大于1000米
      if (this.distance >= 1000) {
        this.$toast('请在绑定停车场一千米内签到')
      } else {
        if (this.signIn === '签到') {
          // 判断是否迟到时间
          this.timeFnLate(this.dueInTime)
          // > 0 迟到
          if (this.fnLateTime > 0) {
            this.lateShow = true
            this.handleClickLateConfirm()
          } else {
            // 正常签到
            const info = {
              scheduleSequence: localStorage.getItem('scheduleSequence')
            }
            this.$signIn.signIn(info).then(res => {
              if (res.resultCode === '2000') {
                this.$toast('签到成功')
                this.signInTime = this.dateTimeValue
                this.signIn = '签退'
              }
            })
          }
        } else if (this.signIn === '签退') {
          // 判断是否早退时间
          this.timeFnBefore(this.dueOutTime)
          // < 0 早退
          if (this.fnBeforeTime < 0) {
            this.beforeShow = true
            this.handleClickBeforeConfirm()
          } else {
            // 正常签退
            const info = {
              scheduleSequence: localStorage.getItem('scheduleSequence')
            }
            this.$signIn.signOut(info).then(res => {
              if (res.resultCode === '2000') {
                this.$toast('签退成功')
                this.signOutTime = this.dateTimeValue
                this.signIn = '本日完成'
              }
            })
          }
        }
      }
    },
    // 迟到点击确认
    handleClickLateConfirm () {
      if (this.lateText === '' || this.lateText === undefined) {
        this.$toast('请输入迟到原因')
      } else {
        // 掉接口，带迟到原因
        const info = {
          scheduleSequence: localStorage.getItem('scheduleSequence'),
          signInRemark: this.lateText
        }
        this.$signIn.signIn(info).then(res => {
          if (res.resultCode === '2000') {
            this.$toast('签到成功')
            this.signInTime = this.dateTimeValue
            this.signIn = '签退'
          }
        })
      }
    },
    // 早退点击确认
    handleClickBeforeConfirm () {
      if (this.beforeText === '' || this.beforeText === undefined) {
        this.$toast('请输入早退原因')
      } else {
        // 掉接口，带早退原因
        const info = {
          scheduleSequence: localStorage.getItem('scheduleSequence'),
          signOutRemark: this.beforeText
        }
        this.$signIn.signOut(info).then(res => {
          if (res.resultCode === '2000') {
            this.$toast('签退成功')
            this.signOutTime = this.dateTimeValue
            this.signIn = '本日完成'
          }
        })
      }
    },
    // 获取当前时间
    getDateTime () {
      var date = new Date()
      var hour = date.getHours()
      var minute = date.getMinutes()
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (minute < 10) {
        minute = '0' + minute
      }
      var second = date.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      this.dateTimeValue =
        year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second + ''
      this.dataTimeLineOne = year + '-' + month + '-' + day
      this.dataTimeLineTwo = hour + ':' + minute + ':' + second
    },
    // 计算时间差
    timeFnLate (time) {
      // 如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      const dateBegin = new Date(time.replace(/-/g, '/'))// 将-转化为/，使用new Date
      const dateEnd = new Date()// 获取当前签到时间
      this.fnLateTime = dateEnd - dateBegin// 时间差的毫秒数
    },
    // 计算时间差
    timeFnBefore (time) {
      // 如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      const dateBegin = new Date(time.replace(/-/g, '/'))// 将-转化为/，使用new Date
      const dateEnd = new Date()// 获取当前签退时间
      this.fnBeforeTime = dateEnd - dateBegin// 时间差的毫秒数
    },
    handleClickRemark () {
      this.$router.push('/signInRemark')
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: #fff;
  .map-container {
    width: 100%;
    height: 50%;
  }
  .top {
    height: 44px;
    line-height: 44px;
  }
  .Info {
    .info-left {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      .left-image {
        width: 14px;
        height: 14px;
        margin-left: -20%;
      }
    }
    .info-right {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
      color: #19a9fc;
    }
    .right-image {
      width: 14px;
      height: 14px;
      margin-left: 30%;
    }
  }
  .signBut {
    width: 100%;
    height: 110px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .signButton {
      background-image: url('../../assets/signIn/signBut.png');
      border-radius: 50%;
      width: 130px;
      height: 130px;
      margin-top: -20px;
      text-align: center;
      .signText {
        width: 100%;
        height: 65px;
        line-height: 90px;
        font-family: PingFang-SC-Bold;
        font-weight: 500;
        font-size: 19px;
        color: white;
        margin-left: 1px;
      }
      .signTime {
        width: 100%;
        font-family: PingFang-SC-Regular;
        font-size: 14px;
        color: white;
        margin-left: 1px;
      }
    }
  }
  .signInTimeClass {
    width: 100%;
    height: 23px;
    text-align: center;
    margin-top: 10px;
    .signInTimeBut {
    }
    .signInTimeText {
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      line-height: 23px;
    }
    .signInTimeContent {
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      line-height: 23px;
      color: #333333;
      margin-left: 3%;
    }
  }
  .signOutTimeClass {
    width: 100%;
    height: 23px;
    text-align: center;
    .signOutTimeBut {
    }
    .signOutTimeText {
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      line-height: 23px;
    }
    .signOutTimeContent {
      font-family: PingFang-SC-Regular;
      font-size: 15px;
      line-height: 23px;
      color: #333333;
      margin-left: 3%;
    }
  }
}
</style>
